@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,300i,400,500");

body {
  background-color: #0a112b;
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
}

.active {
  visibility: hidden;
}

a {
  text-decoration: none;
}

/*h1,*/
/*h2,*/
/*h3,*/
/*h4,*/
/*h5,*/
/*h6,*/
/*p {*/
/*  color: #555;*/
/*}*/

/*h1{*/
/*  font-size:2rem;*/
/*  line-height: 2.5rem;*/
/*  margin: 0;*/
/*}*/
/*h2{*/
/*  font-size: 1.8rem;*/
/*  line-height: 2.7rem;*/
/*}*/
/*h3{*/
/*  font-size: 1.4rem;*/
/*  margin: 4px 0;*/
/*  line-height: 2.1rem;*/
/*}*/
/*h5{*/
/*  font-size: 1.1rem;*/
/*  line-height: 1.8rem;*/
/*  margin: 4px 0;*/
/*}*/


/*li {*/
/*  list-style-type: none;*/
/*}*/

/*#content {*/
/*  grid-area: main;*/
/*  display: grid;*/
/*  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));*/
/*  overflow: auto;*/
/*  resize: horizontal;*/
/*  max-width: 1400px;*/
/*  margin: auto;*/
/*}*/

/*.App {*/
/*  text-align: center;*/
/*  display: grid;*/
/*  grid-template-columns: 1fr;*/
/*  grid-template-rows: auto auto auto auto 1fr auto;*/
/*  grid-template-areas:*/
/*    "header"*/
/*    "backdrop"*/
/*    "profile"*/
/*    "main"*/
/*    "details"*/
/*    "footer";*/
/*    min-height: 100vh;*/
/*}*/

/*p {*/
/*  white-space: pre-wrap;*/
/*  line-height: 1.5rem;*/
/*}*/

/*.categoryHeading {*/
/*  color: #555;*/
/*  padding-top: 2rem;*/
/*}*/

/*!********** HEADER ***********!*/
/*#header {*/
/*  grid-area: header;*/
/*  top: 0px;*/
/*  position: fixed;*/
/*  width: 100%;*/
/*  height: 45px;*/
/*  display: grid;*/
/*  grid-template-columns: auto 1fr;*/
/*  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.28);*/
/*  background: #001122fb;*/
/*  z-index: 100;*/
/*}*/

/* .back {*/
/*   display: inline-block;*/
/*  background: none;*/
/*  color: #2196f3;*/
/*  border: none;*/
/*  font-size: 1.2rem;*/
/*  line-height: 2.2rem;*/
/*  text-align: center;*/
/*  padding: 5px;*/
/*}*/

/*.back span {*/
/*  margin-bottom: 10px;*/
/*}*/

/*.back img {*/
/*  height: 30px;*/
/*  margin: 0 -2px -7px 0;*/

/*}*/


/*!******* CARD *******!*/
/*#card {*/
/*  grid-area: card;*/
/*  min-width: 330px;*/
/*  max-width: 400px;*/
/*  min-height: 155px;*/
/*  max-height: 225px;*/
/*  display: grid;*/
/*  border-radius: 8px;*/
/*  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.18);*/
/*  grid-template-columns: 1fr;*/
/*  grid-template-rows: auto 1fr 1fr auto;*/
/*  grid-template-areas:*/
/*    "profileIMG"*/
/*    "profileName"*/
/*    "mail"*/
/*    "location"*/
/*    "tags";*/
/*  margin: 100px auto auto auto;*/
/*  background: white;*/
/*  margin-top: -90px;*/
/*  color: #707070;*/
/*}*/

/*#profileName {*/
/*  grid-area: profileName;*/
/*  margin: 1px auto;*/
/*}*/

/*#profileIMG {*/
/*  grid-area: profileIMG;*/
/*  border-radius: 50%;*/
/*  border: 3px solid #fff;*/
/*  margin: auto;*/
/*  margin-top: -70px;*/
/*  width: 100px;*/
/*  height: 100px;*/
/*}*/

/*#mail {*/
/*  display: grid;*/
/*  grid-area: mail;*/
/*  grid-template-columns: auto 1fr;*/
/*  grid-template-areas: "mailIcon mailData";*/
/*  padding: 0 50px;*/
/*  align-items: center;*/
/*  justify-items: start;*/
/*  gap: 0.5rem;*/
/*}*/

/*#mailIcon {*/
/*  grid-area: mailIcon;*/
/*}*/

/*#mail-data {*/
/*  grid-area: mailData;*/
/*  line-height: 1.5rem;*/
/*  font-size: 1rem;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  font-weight: 300;*/
/*}*/

/*#location {*/
/*  display: grid;*/
/*  grid-area: location;*/
/*  grid-template-columns: auto 1fr;*/
/*  grid-template-areas: "locationIcon locationData";*/
/*  padding: 0 50px;*/
/*  align-items: center;*/
/*  justify-items: start;*/
/*  gap: 0.5rem;*/
/*}*/

/*#locationIcon {*/
/*  grid-area: locationIcon;*/
/*}*/

/*#locationData {*/
/*  grid-area: locationData;*/
/*  line-height: 1.5rem;*/
/*  font-size: 1rem;*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  font-weight: 300;*/
/*}*/

/*#tags p {*/
/*  font-weight: 100;*/
/*  font-size: 0.8rem;*/
/*  padding: 0 50px;*/
/*}*/
/*!****** BACKDROP *******!*/
/*.backdrop {*/
/*  grid-area: backdrop;*/
/*  width: 100vw;*/
/*  !*   position: fixed;*/
/*  top:45px;*/
/*  z-index: -5; *!*/
/*}*/

/*!******* SCHOOLS ********!*/
/*#schoolComponent {*/
/*  !*   grid-area: schoolComponent; *!*/
/*  margin-top: 3rem;*/
/*}*/

/*.schoolItem {*/
/*  display: grid;*/
/*  grid-template-columns: auto 1fr;*/
/*  grid-template-areas:*/
/*    "schoolImage courseName"*/
/*    "schoolImage schoolName"*/
/*    "schoolImage shortDescription";*/
/*  text-align: left;*/
/*  padding: 0 15px 30px 35px;*/
/*  border-left: 3px solid #d6d6d6;*/
/*  margin-left: 5%;*/
/*  margin-top: 5px;*/
/*}*/

/*.schoolName {*/
/*  grid-area: schoolName;*/
/*  line-height: 1rem;*/
/*  margin: 0;*/
/*  font-weight: 300;*/
/*  font-style: italic;*/
/*}*/

/*.courseName {*/
/*  grid-area: courseName;*/
/*  text-decoration: none;*/
/*}*/

/*.courseName:visited {*/
/*  text-decoration: none;*/
/*}*/

/*.shortDescription {*/
/*  grid-area: shortDescription;*/
/*}*/

/*.schoolImage {*/
/*  grid-area: schoolImage;*/
/*  width: 50px;*/
/*  border-radius: 50%;*/
/*  border: 2px solid #ffffff;*/
/*  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.18);*/
/*  margin-left: -65px;*/
/*  transition: all .2s ease-out;*/
/*}*/

/*.schoolImage:hover, .projectImage:hover {*/
/*  transform: scale(1.05);*/
/*  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.14);*/
/*}*/

/*!******* PROJECTS *******!*/
/*#projectComponent {*/
/*  !*   grid-area: projectComponent; *!*/
/*  margin-top: 3rem;*/
/*}*/

/*.projectItem {*/
/*  display: grid;*/
/*  grid-template-columns: auto 1fr;*/
/*  grid-template-areas:*/
/*    "projectImage projectName"*/
/*    "projectImage projectType"*/
/*    "projectImage projectDescription";*/
/*  text-align: left;*/
/*  padding: 0 15px 30px 35px;*/
/*  margin-left: 5%;*/
/*  margin-top: 5px;*/
/*}*/

/*.projectName {*/
/*  grid-area: projectName;*/
/*  margin: 0;*/
/*}*/

/*.projectType {*/
/*  grid-area: projectType;*/
/*  font-weight: 300;*/
/*  font-style: italic;*/
/*}*/

/*.projectDescription {*/
/*  grid-area: projectDescription;*/
/*}*/

/*.projectImage {*/
/*  grid-area: projectImage;*/
/*  width: 50px;*/
/*  border-radius: 50%;*/
/*  border: 2px solid #ffffff;*/
/*  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.18);*/
/*  margin-left: -65px;*/
/*  transition: all .2s ease-out;*/
/*}*/

/*!******** TECHNOLOGIES *********!*/
/*#technologyComponent {*/
/*  display: grid;*/
/*  grid-template-columns: 1fr;*/
/*  grid-template-rows: auto auto;*/
/*  !*   grid-area: technologyComponent; *!*/
/*  margin-top: 3rem;*/
/*  grid-template-areas:*/
/*    "technologyHeading"*/
/*    "technologyList";*/
/*}*/

/*#technologyComponent .categoryHeading {*/
/*  grid-area: technologyHeading;*/
/*}*/

/*.technologyList {*/
/*  grid-area: technologyList;*/
/*  display: grid;*/
/*  grid-template-columns: 1fr 1fr 1fr;*/
/*  grid-template-rows: 1fr 1fr 1fr;*/
/*  grid-template-areas: "technologyItem technologyItem technologyItem";*/
/*  grid-gap: 15px;*/
/*  margin: 0 auto;*/
/*  padding-left: 0px;*/
/*  align-items: start;*/
/*  align-content: start;*/
/*}*/

/*li .technologyItem {*/
/*  display: grid;*/
/*  grid-area: technologyItem;*/
/*  grid-template-rows: auto 1fr;*/
/*  grid-template-areas:*/
/*    "technologyImage"*/
/*    "technologyName";*/
/*  list-style-type: none;*/

/*}*/

/*.technologyItem .name {*/
/*  grid-area: technologyName;*/
/*  line-height: 1rem;*/
/*  margin: 0;*/
/*}*/

/*.technologyImage {*/
/*  grid-area: technologyImage;*/
/*  width: 95px;*/
/*  height: 95px;*/
/*  border: 2px solid #fff;*/
/*  border-radius: 50%;*/
/*  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.18);*/
/*  margin: auto;*/
/*}*/

/*!******* DETAILS ********!*/

/*!******* SchoolDetails **********!*/
/*#details {*/
/*  display: grid;*/
/*  text-align: left;*/
/*  grid-template-areas: */
/*  "backdrop"*/
/*  "schoolDetailImage"*/
/*  "detailsWrapper";  */
/*}*/

/*.detailsWrapper {*/
/*  display: grid;*/
/*  grid-area: detailsWrapper;*/
/*  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));*/
/*  margin: 0 6%;*/
/*  max-width: 1600px;*/
/*  overflow: auto;*/
/*  resize: horizontal;*/
/*  max-width: 1400px;*/
/*  grid-column-gap: 50px; */
/*}*/

/*.introductionWrapper {*/
/*  display: grid;*/
/*  grid-template-areas: */
/*  "detailName"*/
/*  "subDetailName"*/
/*  "subDetailDuration"*/
/*  "projectlink";*/
/*  align-items: start;*/
/*  grid-template-rows: auto auto auto auto 1fr;*/
/*}*/

/*.projectlink {*/
/*  transition: all .2s ease-out;*/
/*  grid-area: projectlink;*/
/*  color: #2196f3;*/
/*}*/

/*.projectlink:hover {*/
/*  color: #41b6f3;*/
/*}*/

/*.detailImage {*/
/*  grid-area: schoolDetailImage;*/
/*  border-radius: 50%;*/
/*  border: 2px solid #fff;*/
/*  margin-top: -55px;*/
/*  margin-left: 6%;*/
/*  align-self: start;*/
/*  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.18);*/
/*}*/

/*.detailName {*/
/*  grid-area: detailName;*/
/*  padding: 15px 0;*/
/*}*/

/*.subDetailName {*/
/*  grid-area: subDetailName;*/
/*}*/

/*.subDetailDuration {*/
/*  grid-area: subDetailDuration;*/
/*}*/

/*.problemsToSolve {*/
/*  display: grid;*/
/*}*/

/*!********** GALLERY **********!*/
/*.gallery {*/
/*  display: grid;*/
/*  width: 100%;*/
/*!*   grid-template-columns: 1fr 1fr; *!*/
/*  grid-gap: 10px;*/
/*}*/

/*.gallery img {*/
/*  max-width: 98%;*/
/*  border-radius: 8px;*/
/*  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.14);*/
/*  border: 1px solid rgba(0, 0, 0, 0.08);*/
/*}*/

/*img.galleryImage:nth-child(2), img.galleryImage:nth-child(1) {*/
/*  grid-column: -1 / span 2;*/
/*}*/

/*!********** FOOTER  ***********!*/
/*#footer {*/
/*background: #001122fb;*/
/*padding: 20px;*/
/*margin-top: 30px;*/
/*bottom: 0px;*/
/*align-self: end;*/
/*grid-area: footer;*/
/*}*/

/*#builtwith {*/
/*  color: #aaaaaa;*/
/*}*/

/*#footer a {*/
/*  color: #2196f3;*/
/*}*/

/*#footer a:hover {*/
/*  color: #41b6f3;*/
/*}*/

/*#languages .categoryHeading {*/
/*  text-align: start;*/
/*  margin-left: 5%;*/
/*  margin-top: 5px;*/
/*  padding: 0 15px 30px 75px;*/
/*}*/

/*#languages p {*/
/*  text-align: left;*/
/*  margin-left: 5%;*/
/*  padding: 0 15px 10px 75px;*/
/*}*/