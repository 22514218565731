body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
  scrollbar-gutter: stable both-edges;
  overflow-x: hidden;
  overflow-y: hidden;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
}

html::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background: #1282a2;
  outline: none;
  border-radius: 7px;
}